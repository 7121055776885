














































































































































import SKHeader from '@/components/SK-Header.vue';
import { Vue, Component } from 'vue-property-decorator';
import SKBodyFrontMale from '@/components/sk-body/SK-BodyFrontMale.vue';
import SKBodyFrontFemale from '@/components/sk-body/SK-BodyFrontFemale.vue';
import SKBodyBackMale from '@/components/sk-body/SK-BodyBackMale.vue';
import SKBodyBackFemale from '@/components/sk-body/SK-BodyBackFemale.vue';
import { IPosition, ISKNotification } from '@/utils/interfaces';
import {
  complete,
  copyToClipboard,
  hasCompleted,
  hasLiked,
  like
} from '@/utils/functions';
import { getText } from '@/utils/langs';

@Component({
  components: {
    SKHeader,
    SKBodyFrontMale,
    SKBodyFrontFemale,
    SKBodyBackMale,
    SKBodyBackFemale
  }
})
export default class PositionsDetail extends Vue {
  public position: IPosition | null = null;

  mounted() {
    this.loadPosition();
  }

  public loadPosition(): void {
    this.position = this.$store.getters.position(this.id);
    if (!this.position) {
      setTimeout(() => {
        this.loadPosition();
      }, 200);
    }
  }

  get title(): string | undefined {
    if (this.position) return getText(`position.${this.id}.title`);
    return undefined;
  }

  get id(): string {
    return this.$route.params.id;
  }

  get musclesMan(): string {
    if (!this.position) return '';
    return [
      ...this.position.primaryMusclesMan,
      ...this.position.secondaryMusclesMan
    ].join(', ');
  }

  get musclesWoman(): string {
    if (!this.position) return '';
    return [
      ...this.position.primaryMusclesWoman,
      ...this.position.secondaryMusclesWoman
    ].join(', ');
  }

  get liked(): boolean {
    return hasLiked(this.id);
  }

  get completed(): boolean {
    return hasCompleted(this.id);
  }

  public like(): void {
    like(this.id);
  }

  public complete(): void {
    complete(this.id);
  }

  public share(): void {
    if (this.position) {
      const url = window.location.href;
      const share: ShareData = {
        title: 'SportKamasutra',
        text: getText(`position.${this.id}.title`),
        url: url
      };

      try {
        navigator
          .share(share)
          .then(() => {
            /** */
          })
          .catch(this.copyToClip);
      } catch (_) {
        this.copyToClip();
      }
    }
  }

  private copyToClip() {
    copyToClipboard(window.location.href);
    this.$store.commit('sendNotification', {
      text: window.location.href,
      title: 'Copied URL to your clipboard'
    } as ISKNotification);
  }
}
